import { useEffect, useState } from "react";
import styled from "styled-components";
import Countdown from "react-countdown";
import { Button, CircularProgress, Container, Snackbar } from "@material-ui/core";
import Alert from "@material-ui/lab/Alert";
import './Home.css';

import * as anchor from "@project-serum/anchor";

import { LAMPORTS_PER_SOL } from "@solana/web3.js";

import { useAnchorWallet } from "@solana/wallet-adapter-react";
import { WalletDialogButton } from "@solana/wallet-adapter-material-ui";

import gifImage from './images/mint.gif';
import logo_dark from './images/logo_dark.png';


import {
  CandyMachine,
  awaitTransactionSignatureConfirmation,
  getCandyMachineState,
  mintOneToken,
  shortenAddress,
} from "./candy-machine";
// import { start } from "repl";

const ConnectButton = styled(WalletDialogButton)``;

const CounterText = styled.span``; // add your styles here

const MintContainer = styled.div``; // add your styles here

const MintButton = styled(Button)``; // add your styles here

export interface HomeProps {
  candyMachineId: anchor.web3.PublicKey;
  config: anchor.web3.PublicKey;
  connection: anchor.web3.Connection;
  startDate: number;
  treasury: anchor.web3.PublicKey;
  txTimeout: number;
}

const Home = (props: HomeProps) => {
  const [balance, setBalance] = useState<number>();
  // const [isActive, setIsActive] = useState(false); // true when countdown completes
  const [isSoldOut, setIsSoldOut] = useState(false); // true when items remaining is zero
  // const [classNameSoldOut, setclassNameSoldOut] = useState<string>(); // true when items remaining is zero
  const [isMinting, setIsMinting] = useState(false); // true when user got to press MINT

  const [itemsAvailable, setItemsAvailable] = useState(0);
  const [itemsRedeemed, setItemsRedeemed] = useState(0);
  const [itemsRemaining, setItemsRemaining] = useState(0);

  const [alertState, setAlertState] = useState<AlertState>({
    open: false,
    message: "",
    severity: undefined,
  });

  const [startDate, setStartDate] = useState(new Date(props.startDate));

  const wallet = useAnchorWallet();
  const [candyMachine, setCandyMachine] = useState<CandyMachine>();

  const refreshCandyMachineState = () => {
    (async () => {
      if (!wallet) return;

      const {
        candyMachine,
        goLiveDate,
        itemsAvailable,
        itemsRemaining,
        itemsRedeemed,
      } = await getCandyMachineState(
        wallet as anchor.Wallet,
        props.candyMachineId,
        props.connection
      );

      setItemsAvailable(itemsAvailable);
      setItemsRemaining(itemsRemaining);
      setItemsRedeemed(itemsRedeemed);

      setIsSoldOut(itemsRemaining === 0);
      setStartDate(goLiveDate);
      setCandyMachine(candyMachine);
      

    })();
  };

  function textNameClass(){
    
      if (isSoldOut) {
        return ("button soldOut");
      }
        
      return ("button");
    
  }

  const onMint = async () => {
    try {
      setIsMinting(true);
      if (wallet && candyMachine?.program) {
        const mintTxId = await mintOneToken(
          candyMachine,
          props.config,
          wallet.publicKey,
          props.treasury
        );

        const status = await awaitTransactionSignatureConfirmation(
          mintTxId,
          props.txTimeout,
          props.connection,
          "singleGossip",
          false
        );

        if (!status?.err) {
          setAlertState({
            open: true,
            message: "Congratulations! Mint succeeded!",
            severity: "success",
          });
        } else {
          setAlertState({
            open: true,
            message: "Mint failed! Please try again!",
            severity: "error",
          });
        }
      }
    } catch (error: any) {
      // TODO: blech:
      let message = error.msg || "Minting failed! Please try again!";
      if (!error.msg) {
        if (error.message.indexOf("0x138")) {
        } else if (error.message.indexOf("0x137")) {
          message = `SOLD OUT!`;
        } else if (error.message.indexOf("0x135")) {
          message = `Insufficient funds to mint. Please fund your wallet.`;
        }
      } else {
        if (error.code === 311) {
          message = `SOLD OUT!`;
          setIsSoldOut(true);
        } else if (error.code === 312) {
          message = `Minting period hasn't started yet.`;
        }
      }

      setAlertState({
        open: true,
        message,
        severity: "error",
      });
    } finally {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
      setIsMinting(false);
      refreshCandyMachineState();
    }
  };

  useEffect(() => {
    (async () => {
      if (wallet) {
        const balance = await props.connection.getBalance(wallet.publicKey);
        setBalance(balance / LAMPORTS_PER_SOL);
      }
    })();
  }, [wallet, props.connection]);

  useEffect(refreshCandyMachineState, [
    wallet,
    props.candyMachineId,
    props.connection,
  ]);


  return (
    <main>

    <Container style={{ height:"100vh"}}>

      <div className="d-flex  flex-column min-vh-100 justify-content-center">
        <div className="row p-3 m-1 contentMint">
         
          <div className="col-12 col-md-6 d-flex align-items-center">
            <img src={gifImage} className="img-fluid imgGif" alt="Gif of MonkCrew"/>
          </div>
          <div className="col-12 col-md-6 text-center">

              <img src={logo_dark} className="mt-5 d-none d-sm-inline" alt="Logo"/>
              <h1 className=" mt-3 title">Mint your Monk Crew</h1>
              <p className="descMint mt-5">Connect your Wallet and press the Mint button</p>
              <p className="price mt-4">0.9 SOL</p>

              {wallet && (
                <p className="wallet_address mt-2">Your Wallet:<br/>{shortenAddress(wallet.publicKey.toBase58() || "")}</p> 
              )}
              {(startDate > new Date(Date.now())) && (
                <p className="dateMint mt-1">Available: <span>{startDate.toLocaleDateString() + " " + startDate.toLocaleTimeString()}</span></p> 
              )}

              {/* {wallet && <p>Total Available: {itemsAvailable}</p>}

              {wallet && <p>Remaining: {itemsRemaining}</p>}  */}

              {!wallet ? (
                <ConnectButton className=" button mt-4">Connect Wallet</ConnectButton>
              ) : (
                <MintButton
                  disabled={isSoldOut || isMinting || (startDate > new Date(Date.now()))}
                  onClick={onMint}
                  variant="contained"
                  className = {textNameClass()}
                >
                  {(startDate > new Date(Date.now())) ? (
                    "Coming Soon"
                  ) : isSoldOut ? (
                    "SOLD OUT"
                  ) : isMinting ? (
                      <CircularProgress />
                    ) : (
                      "Mint Now"
                    )}
                </MintButton>
              )}

          

          </div>
          
        </div>
      </div>
    
    </Container>

   
      
      <Snackbar
        
        open={alertState.open}
        autoHideDuration={60000}
        onClose={() => setAlertState({ ...alertState, open: false })}
      >
        <Alert
          className="snackBar"
          onClose={() => setAlertState({ ...alertState, open: false })}
          severity={alertState.severity}
        >
          {alertState.message}
        </Alert>
      </Snackbar>
    </main>
  );
};

interface AlertState {
  open: boolean;
  message: string;
  severity: "success" | "info" | "warning" | "error" | undefined;
}

const renderCounter = ({ days, hours, minutes, seconds, completed }: any) => {
  return (
    <CounterText>
      {hours + (days || 0) * 24} hours, {minutes} minutes, {seconds} seconds
    </CounterText>
  );
};



export default Home;
